exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-birthday-js": () => import("./../../../src/pages/birthday.js" /* webpackChunkName: "component---src-pages-birthday-js" */),
  "component---src-pages-cb-gallery-js": () => import("./../../../src/pages/CBGallery.js" /* webpackChunkName: "component---src-pages-cb-gallery-js" */),
  "component---src-pages-coffee-js": () => import("./../../../src/pages/coffee.js" /* webpackChunkName: "component---src-pages-coffee-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dogs-js": () => import("./../../../src/pages/dogs.js" /* webpackChunkName: "component---src-pages-dogs-js" */),
  "component---src-pages-employment-js": () => import("./../../../src/pages/employment.js" /* webpackChunkName: "component---src-pages-employment-js" */),
  "component---src-pages-food-js": () => import("./../../../src/pages/food.js" /* webpackChunkName: "component---src-pages-food-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-made-in-stockport-24-js": () => import("./../../../src/pages/madeInStockport24.js" /* webpackChunkName: "component---src-pages-made-in-stockport-24-js" */),
  "component---src-pages-made-in-stockport-js": () => import("./../../../src/pages/madeInStockport.js" /* webpackChunkName: "component---src-pages-made-in-stockport-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-sba-js": () => import("./../../../src/pages/sba.js" /* webpackChunkName: "component---src-pages-sba-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

